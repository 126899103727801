import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Input, TextArea, Button, ConfirmationDialog } from "../../common";
import { useNotificationStore } from "../../store/notifications";
import { uploadQuote } from "./pdf/uploadQuote";
import { QuotesApi } from "../../api";

const emailTemplateHTML = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Quote from Frontier Scaffolding</title>
    <style>
        body {
            font-family: 'Segoe UI', Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f9f9f9;
        }

        .email-container {
            max-width: 600px;
            margin: 40px auto;
            background-color: #ffffff;
            border: 1px solid #e0e0e0;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
            padding: 0;
            border-radius: 8px;
            overflow: hidden;
        }

        .header {
            background-color: #0056a6;
            padding: 20px 30px;
            color: #ffffff;
            text-align: center;
        }

        .header img {
            max-width: 150px;
            height: auto;
            margin-bottom: 10px;
        }

        .header h1 {
            margin: 0;
            font-size: 22px;
            font-weight: 600;
        }

        .body-content {
            padding: 30px;
            font-size: 16px;
            line-height: 1.6;
            color: #333333;
        }

        .body-content p {
            margin: 1em 0;
        }

        .footer {
            padding: 20px 30px;
            background-color: #f2f2f2;
            font-size: 14px;
            color: #666666;
            border-top: 1px solid #e0e0e0;
        }

        .footer p {
            margin: 5px 0;
        }

        .footer a {
            color: #0056a6;
            text-decoration: none;
        }
    </style>
</head>

<body>
    <div class="email-container">
        <div class="header">
            <img src="https://frontier.scaffm8.co.nz/logo.png" alt="Frontier Scaffolding Logo">
            <h1>Your Quote from Frontier Scaffolding</h1>
        </div>
        <div class="body-content">
            {{body}}
        </div>
        <div class="footer">
            <p>Best regards,</p>
            <p>Rob Grahame<br>
                Phone: <a href="tel:0279214866">027 921 4866</a><br>
                Email: <a href="mailto:rob@frontierscaffolding.co.nz">rob@frontierscaffolding.co.nz</a>
            </p>
        </div>
    </div>
</body>

</html>
`;

export const EmailSent = ({ quoteId, emailStatus, quote }) => {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const updateQuoteMutation = QuotesApi.useUpdateQuote();

  const [state, setState] = useState({
    subject: `Frontier Scaffolding Quote ${quote.quote_num}`,
    body: `Hi ${quote.client_contacts?.name || ""},
Please find the attached file to view our quote for ${quote.street_1} ${quote.city}
To accept the quote you can reply to this email.

This quote is valid for 30 days.`,
  });

  const newStatus = emailStatus === "Ready to send" ? "Sent" : "Ready to send";

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setState({
      ...state,
      [id]: value,
    });
  };

  return (
    <ConfirmationDialog
      isDone={updateQuoteMutation.isSuccess}
      icon="info"
      title="Sent Quote"
      body={`Are you sure you wish to mark this quote as ${newStatus} ? ${
        newStatus === "Sent" ? "This action will send the quote to the client" : ""
      }`}
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400"
        >
          {emailStatus === "Ready to send" && (
            <CheckIcon className="-ml-0.5 mr-2 h-4 w-4 text-green-400" aria-hidden="true" />
          )}
          {emailStatus === "Sent" && (
            <XIcon className="-ml-0.5 mr-2 h-4 w-4 text-red-400" aria-hidden="true" />
          )}
          {emailStatus}
        </button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            if (!state.body || !state.subject) {
              return;
            }
            setIsLoading(true);
            if (newStatus === "Sent") {
              const urlQuote = await uploadQuote(quoteId);
              const body = state.body.split("\n").join(" <br /> ");
              fetch("https://frontier-server-scaffm8.herokuapp.com/api/quote-email", {
                method: "POST", // or 'PUT'
                body: JSON.stringify({
                  subject: state.subject,
                  text: emailTemplateHTML.replace("{{body}}", body),
                  emailTo: quote?.client_contacts?.email,
                  sender: quote?.staff?.email || null,
                  url: urlQuote,
                  quoteId: quote?.quote_num,
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((res) => res.json())
                .catch((error) =>
                  addNotification({
                    isSuccess: false,
                    heading: "Error!",
                    content: `Network Error With Xero.`,
                  }),
                )
                .then(async (response) => {
                  let isSuccess = true;
                  let content = "Success!";
                  if (response.err) {
                    isSuccess = false;
                    content = "Error!";
                  }
                  const heading = response.message;
                  addNotification({
                    isSuccess,
                    heading,
                    content,
                  });
                  try {
                    const result = await updateQuoteMutation.mutateAsync({
                      quote: { emailStatus: newStatus },
                      quoteId,
                    });
                    window.location.reload();
                  } catch (error) {/* eslint-disable-next-line no-empty */}
                  setIsLoading(false);
                });
            } else {
              try {
                const result = await updateQuoteMutation.mutateAsync({
                  quote: { emailStatus: newStatus },
                  quoteId,
                });
                window.location.reload();
              } catch (error) {/* eslint-disable-next-line no-empty */}
              setIsLoading(false);
            }
          }}
        >
          Mark as {newStatus}
        </Button>
      }
    >
      <>
        {newStatus === "Sent" && (
          <>
            <div className="flex">
              <div className="w-1/2">
                <Input
                  title="Subject"
                  id="subject"
                  type="text"
                  value={state.subject}
                  handleChange={handleInputChange}
                  error={!state.subject ? "Subject Is Requied" : null}
                />
              </div>
            </div>
            <TextArea
              title="Body Text"
              id="body"
              type="text"
              value={state.body}
              handleChange={handleInputChange}
              rows={8}
              error={!state.body ? "Body Is Requied" : null}
            />
          </>
        )}
      </>
    </ConfirmationDialog>
  );
};
