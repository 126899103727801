/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { RadioButton } from "primereact/radiobutton";
import { useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";
import clsx from "clsx";
import supabase from "../../api/supabase";

import { ClientForm } from "../Clients";
import { ContactForm } from "../Contacts";
import { Input, TextArea, Dropdown, Address, QuoteTab, Button, Spinner } from "../../common";

import { QuoteLines } from "./QuoteLines";
import { Rates } from "./Rates/Rates";
import { AdditionalItems } from "./AdditionalItems/AdditionalItems";
import { Totals } from "./QuoteTotals";
import { useActiveClients } from "../../api/Clients";
import { useStaff } from "../../api/Staff";
import { useJobs } from "../../api/Jobs";
import { useFetchQuote, useFetchQuoteImages } from "../../api/Quotes";
import { fetchAllContacts } from "../../api/ClientContacts";

import { useNotificationStore } from "../../store/notifications";

import {
  formatAddons,
  formatQuoteLines,
  formatQuotePortalLines,
  formatPortalAddons,
  formatZones,
  formatRates,
  formatImages,
  tabs,
  zoneOptions,
  quoteRates,
  quoteTerms,
} from "./utils";
import { QuotePhotos } from "./QuotePhotos";

import { QuotesApi, FilesApi } from "../../api";

export const quoteTypeOptions = [
  { value: "New", label: "New" },
  { value: "Variation", label: "Variation" },
];

export const clientTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Construction", label: "Construction" },
  { value: "Civil", label: "Civil" },
  { value: "Residential", label: "Residential" },
];

const brandingOptions = [{ value: "Frontier", label: "Frontier" }];

const estimatedWayOptions = [
  { value: "Meters", label: "Meters" },
  { value: "Total ED", label: "Total ED" },
];

export function QuoteForm({ formType = "create" }) {
  const history = useHistory();
  const [redirectOutput, setRedirectOutput] = useState(false);
  const { addNotification } = useNotificationStore();

  const user = supabase.auth.user();

  const [loggedUser, setLoggedUser] = useState(null);

  // Client Form
  const [clientForm, setClientForm] = useState(false);
  const [clientId, setClientId] = useState(null);

  // Contact Form
  const [contactForm, setContactForm] = useState(false);
  const [contactId, setContactId] = useState(null);

  const [transportRate, setTransportRate] = useState(0);
  const [fetchedQuote, setFetchedQuote] = useState([]);
  const [quoteCreating, setQuoteCreating] = useState(false);
  // Quote Lines
  const [estimatedWay, setEstimatedWay] = useState(null);
  const [quoteLines, setQuoteLines] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  const [termValues, setTermValues] = useState({
    standardRate: 70,
    nonStandardRate: 100,
    smallTruck: 200,
    hiabTruck: 300,
  });

  // Quote Zones
  const [zoneValues, setZoneValues] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([{ id: 1, label: "Scaffold", zone_id: "" }]);
  const [zones, setZones] = useState(null);

  // Totals
  const [weekTotal, setWeekTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [transportTotal, setTransportTotal] = useState(0);
  const [erectDismantleTotal, setErectDismantleTotal] = useState(0);
  const [additionalTotal, setAdditionalTotal] = useState(0);

  // Curent Quote Tab
  const [currentTab, setCurrentTab] = useState("Rates");

  const [quoteNum, setQuoteNum] = useState(null);
  const [client, setClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [rates, setRates] = useState([]);
  const clientData = useActiveClients();
  const staffData = useStaff();
  const jobData = useJobs();

  const { quoteId } = useParams();
  const { data: quotePhotosData } = useFetchQuoteImages(quoteId);
  useEffect(async () => {
    if (formType === "create") {
      const ratesData = await QuotesApi.fetchRates();
      setRates(ratesData);
    }

    // Generate next quote #
    const quotes = await QuotesApi.getQuoteNum();

    const lastQuoteNum = quotes?.quote_num?.split("-")?.[0];
    const nextNumSeq = lastQuoteNum ? Number(lastQuoteNum) + 1 : 1000;

    setQuoteNum(`${String(nextNumSeq)}-1`);
    handleZoneChange(1);
  }, []);

  useEffect(() => {
    renderClientList();
  }, [clientId, clientForm]);

  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);

  useEffect(async () => {
    let isCurrent = true;

    if (quoteId) {
      const quote = await QuotesApi.fetchQuote(quoteId);
      setZones(quote?.quote_zones?.length);

      if (isCurrent) {
        const contactsRes = await fetchAllContacts(quote?.client);
        let contacts;
        if (contactsRes && contactsRes.length > 0) {
          contacts = contactsRes.map((contact) => ({
            label: contact.name,
            value: contact.id,
          }));
        } else {
          contacts = [];
        }

        setContactList(contacts);
        setFetchedQuote(quote);
        setRates(quote.quote_rates);

        const formatQuoteLines = formatQuotePortalLines(quote?.quote_lines);
        const formQuoteAddons = formatPortalAddons(quote?.quote_addons);
        const zoneLineItems = zoneOptions.slice(0, quote?.quote_zones.length);

        const labelsArr = quote?.quote_zones.map((item, index) => ({
          zone_id: item.id,
          id: item.zone_id,
          label: String(item.zone_label).trim(),
        }));

        setZoneLabels(labelsArr);
        setEstimatedWay(quote.estimatedWay);
        setZoneValues(zoneLineItems);
        setQuoteLines(formatQuoteLines);
        setAdditionalItems(formQuoteAddons);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [quoteId]);

  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);

  useEffect(() => {
    let totalWeek = 0;
    let totalAmount = 0;
    let totalAdditionalItems = 0;
    let totalErectDismantle = 0;

    if (quoteLines.length) {
      totalWeek += quoteLines.reduce((acc, curr) => acc + Number(curr.hireFee), 0);
      totalErectDismantle += quoteLines.reduce((acc, curr) => acc + Number(curr.dismantle), 0);
    }
    if (additionalItems.length) {
      totalAdditionalItems += additionalItems.reduce(
        (acc, curr) => acc + Number(curr.totalCost),
        0,
      );
    }
    totalAmount += totalErectDismantle + totalAdditionalItems + totalWeek;

    setTotal(totalAmount);
    setWeekTotal(totalWeek);
    setErectDismantleTotal(totalErectDismantle);
    setAdditionalTotal(totalAdditionalItems);
  }, [quoteLines, additionalItems, erectDismantleTotal, additionalTotal]);

  // MUTATIONS
  const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const updateZonesMutation = QuotesApi.useUpdateZones();
  const updateLinesMutation = QuotesApi.useUpdateLines();
  const updateAddonsMutation = QuotesApi.useUpdateAddons();
  const updateRatesMutation = QuotesApi.useUpdateRates();
  const updateImagesMutation = QuotesApi.useUpdateImages();

  const createQuoteMutation = QuotesApi.useCreateQuote();
  const createZonesMutation = QuotesApi.useCreateZones();
  const createLinesMutation = QuotesApi.useCreateLines();
  const createAddonsMutation = QuotesApi.useCreateAddons();
  const createRatesMutation = QuotesApi.useCreateRates();
  const createImagesMutation = QuotesApi.useCreateImages();
  const createFileMutation = FilesApi.useCreateFile();

  const renderClientList = () => {
    if (clientData && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data.map((staff) => ({
        label: staff.staff_name,
        value: staff.id,
        email: staff.email,
      }));
    }
    return [];
  };

  const renderJobList = () => {
    if (jobData && jobData?.data?.length > 0) {
      return jobData.data.map((job) => ({
        label: `${job.id + 1000} - ${job.site}`,
        value: job.id,
      }));
    }
    return [];
  };
  const renderContactList = () => {/* eslint-disable-next-line no-empty */};

  const handleZoneChange = (val) => {
    const zoneVal = Number(val);

    const labelsArr = Array(zoneVal)
      .fill()
      .map((obj, index) => ({
        zone_id: zoneLabels[index]?.zone_id || "",
        id: index + 1,
        label: zoneLabels[index]?.label || "",
      }));
    setZoneLabels(labelsArr);
    setZones(val);

    const zoneLineItems = zoneOptions.slice(0, zoneVal);
    setZoneValues(zoneLineItems);
  };

  const handleZoneLabelChange = (val, index) => {
    setZoneLabels(
      zoneLabels.map((item) => {
        if (index === Number(item.id)) {
          return { ...item, label: val.target.value };
        }
        return item;
      }),
    );
  };

  const loggedInUser = () => {
    const staffArr = staffData?.data?.find((staff) => staff.email === user?.email);
    return staffArr?.id;
  };

  if (!rates.length || quoteNum === null) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (quoteId && !fetchedQuote.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  return (
    <div className="mb-10">
      <div className="">
        <Formik
          initialValues={{
            quote_type: "New",
            PO_Number: "",
            variation_job_id: fetchedQuote.variation_job_id || "",
            quote_num: formType === "edit" ? `${fetchedQuote.quote_num}` : `${quoteNum}`,
            branding: fetchedQuote.branding || "Frontier",
            clientType: fetchedQuote.clientType || "Residential",
            client: fetchedQuote.client || clientId,
            version: fetchedQuote?.version || 1,
            estimator: fetchedQuote?.estimator || loggedInUser(),
            contact: fetchedQuote.contact_id || contactId,
            maxZones: fetchedQuote.max_zones ? String(fetchedQuote.max_zones) : "1",
            description:
              fetchedQuote.description ||
              "Scaffolding for Roof Edge Protection (top working platform to be 1m below the roof edge) for the construction of the house.",
            street: fetchedQuote?.street_1 || "",
            street2: fetchedQuote?.street_2 || "",
            city: fetchedQuote?.city || "",
            postal: fetchedQuote.post_code || "",
            transport_total: fetchedQuote || transportRate,
            terms: fetchedQuote?.terms || quoteTerms,
            status: fetchedQuote?.status || "Pending",
            estimatedWay: fetchedQuote?.estimatedWay || null,
          }}
          validate={(values) => {
            const errors = {/* eslint-disable-next-line no-empty */};
            if (values.quote_type === "Variation" && !values.variation_job_id) {
              errors.variation_job_id = "Job is required.";
            }
            if (!values.estimatedWay) {
              errors.estimatedWay = "How will this job be estimated? is required";
            }

            if (!values.client) {
              errors.client = "Client is required.";
            }

            if (!values.contact) {
              errors.contact = "Contact is required.";
            }

            /*
            if (!values.estimator) {
              errors.estimatedWay = "Estimator is required";
            }

            if (!values.quote_num) {
              errors.quote_num = "Quote # is required.";
            }
            if (!values.client) {
              errors.client = "Client is required.";
            }
            if (!values.estimator) {
              errors.estimator = "Estimator is required.";
            }
            */
            const zoneEmpty = zoneLabels.find((e) => e.label === "");

            if (zoneEmpty !== undefined) {
              errors.zoneLabels = "Is required.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // console.time("CREATING");
            setQuoteCreating(true);
            const {
              quote_type,
              PO_Number,
              variation_job_id,
              quote_num,
              client,
              version,
              contact,
              estimator,
              clientType,
              maxZones,
              description,
              street,
              street2,
              city,
              postal,
              terms,
              status,
              estimatedWay,
            } = values;

            const quotePayload = {
              quote_type,
              PO_Number,
              variation_job_id: variation_job_id || null,
              quote_num,
              version,
              clientType,
              branding: values.branding || "",
              client: client || null,
              contact_id: contact || null,
              estimator: estimator || null,
              created_by: user?.user_metadata?.name,
              max_zones: maxZones,
              description,
              street_1: street,
              street_2: street2,
              city,
              post_code: postal,
              terms: String(terms),
              transport_total: Number(transportTotal),
              weekly_total: Number(weekTotal) || null,
              total_amount: Number(total) || null,
              status,
              estimatedWay,
              additionalTotal: Number(additionalTotal),
              erectDismantleTotal: Number(erectDismantleTotal),
            };
            if (formType === "edit") {
              try {
                // console.time("TIMER");
                const quoteResult = await updateQuoteMutation.mutateAsync(
                  {
                    quote: quotePayload,
                    quoteId: fetchedQuote?.id,
                  },
                  {
                    onSuccess: (payload) => {
                      const zones = formatZones(zoneLabels, fetchedQuote?.id, "edit");
                      const lines = formatQuoteLines(quoteLines, fetchedQuote?.id, "edit");
                      const addons = formatAddons(additionalItems, fetchedQuote?.id, "edit");
                      const formatedRates = formatRates(rates, fetchedQuote?.id, "edit");
                      const formatedImages = formatImages(values, quoteId);

                      updateZonesMutation.mutate(zones, {
                        onSuccess: (payload) => {/* eslint-disable-next-line no-empty */},
                        onError: (error) => {/* eslint-disable-next-line no-empty */},
                      });

                      updateLinesMutation.mutate(lines, {
                        onSuccess: (payload) => {/* eslint-disable-next-line no-empty */},
                        onError: (error) => {/* eslint-disable-next-line no-empty */},
                      });

                      updateRatesMutation.mutate(formatedRates, {
                        onError: (error) => {/* eslint-disable-next-line no-empty */},
                      });

                      updateImagesMutation.mutate(formatedImages, {
                        onError: (error) => {/* eslint-disable-next-line no-empty */},
                      });


                      updateAddonsMutation.mutate(addons, {
                        onSuccess: (payload) => {
                          setQuoteCreating(false);

                          if (redirectOutput) {
                            history.push(`/quotes/${fetchedQuote?.id}/output`);
                          } else {
                            history.push("/quotes");
                          }

                          addNotification({
                            isSuccess: true,
                            heading: "Success!",
                            content: `Successfully updated quote!`,
                          });
                        },
                        onError: (error) => {
                          setQuoteCreating(false);

                          history.push("/quotes");
                          addNotification({
                            isSuccess: false,
                            heading: "Failure!",
                            content: `Failed to update quote. ${error?.message}`,
                          });
                        },
                      });
                    },
                    onError: (error) => {/* eslint-disable-next-line no-empty */},
                  },
                );
              } catch (err) {/* eslint-disable-next-line no-empty */}
            } else {
              createQuoteMutation.mutate(quotePayload, {
                onSuccess: (payload) => {
                  const quoteId = payload?.[0]?.id;

                  // Format payloads
                  const zones = formatZones(zoneLabels, quoteId);
                  const addons = formatAddons(additionalItems, quoteId);
                  const lines = formatQuoteLines(quoteLines, quoteId);
                  const formatedRates = formatRates(rates, quoteId);

                  const formatedImages = formatImages(values, quoteId);

                  // CREATE ZONES
                  createZonesMutation.mutate(zones, {
                    onError: (error) => {/* eslint-disable-next-line no-empty */},
                  });

                  // CREATE ZONES
                  createLinesMutation.mutate(lines, {
                    onError: (error) => {/* eslint-disable-next-line no-empty */},
                  });

                  createRatesMutation.mutate(formatedRates, {
                    onError: (error) => {/* eslint-disable-next-line no-empty */},
                  });

                  // CREATE QUOTE IMAGES
                  // 
                  createImagesMutation.mutate(formatedImages, {
                    onError: (error) => {/* eslint-disable-next-line no-empty */},
                    onSuccess: () => {/* eslint-disable-next-line no-empty */},
                  });

                  // CREATE QUOTE ADDONS
                  createAddonsMutation.mutate(addons, {
                    onSuccess: (payload) => {
                      setQuoteCreating(false);

                      history.push(`/quotes/${quoteId}/details`);
                      addNotification({
                        isSuccess: true,
                        heading: "Success!",
                        content: `Successfully created quote!`,
                      });
                    },
                    onError: (err) => {
                      setQuoteCreating(false);

                      // history.push("/quotes");
                      addNotification({
                        isSuccess: false,
                        heading: "Failure!",
                        content: `Failed to create quote. ${err?.message}`,
                      });
                    },
                  });
                },
                onError: (err) => {
                  setQuoteCreating(false);
                },
              });
            }
            
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div className="w-full">
              <div className="flex">
                <form onSubmit={handleSubmit} id="quoteForm" className="w-4/6">
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="New / Variation"
                        id="quote_type"
                        value={values.quote_type}
                        onChange={(opt, value) => {
                          setFieldValue("quote_type", value);
                          // setClientId(value);
                          // setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        options={quoteTypeOptions}
                      />
                    </div>
                  </div>

                  {values.quote_type === "Variation" ? (
                    <div className="flex">
                      <div className="w-1/2">
                        <Input
                          title="PO Number"
                          id="PO_Number"
                          type="text"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.PO_Number}
                          error={errors.PO_Number}
                        />
                      </div>
                      <div className="w-1/2">
                        <Dropdown
                          label="Job"
                          id="variation_job_id"
                          value={values.variation_job_id}
                          onChange={async (opt, value) => {
                            setFieldValue("variation_job_id", value);

                            // reset field values
                            setFieldValue("client", "");
                            setFieldValue("contact", "");
                            setFieldValue("street", "");
                            setFieldValue("street2", "");
                            setFieldValue("city", "");
                            setFieldValue("postal", "");
                            // Find matching job and quote
                            const jobMatch = jobData.data.find((job) => job.id === value);

                            if (jobMatch && jobMatch.quote_id) {
                              try {
                                const { data, error } = await supabase
                                  .from("quotes")
                                  .select("*")
                                  .eq("id", Number(jobMatch.quote_id));
                                const quote = data?.[0];
                                if (quote) {
                                  const oldQuoteNumber = quote?.quote_num?.split("-");
                                  let { data } = await supabase.from("quotes");
                                  data = data.map((row) => row.quote_num.split("-"));
                                  data = data.filter((e) => oldQuoteNumber[0] === e[0]);

                                  setFieldValue("client", quote?.client);
                                  setFieldValue(
                                    "quote_num",
                                    `${oldQuoteNumber[0]}-V${data.length}`,
                                  );
                                  setClientId(quote?.client);
                                  setClient(quote?.client);
                                  setFieldValue("clientType", quote?.clientType || "");
                                  setFieldValue("maxZones", String(quote?.max_zones) || "");
                                  setFieldValue("contact", quote?.contact_id || "");
                                  setFieldValue("street", quote?.street_1 || "");
                                  setFieldValue("street2", quote?.street_2 || "");
                                  setFieldValue("city", quote?.city || "");
                                  setFieldValue("postal", quote?.post_code || "");
                                  setFieldValue("estimator", quote?.estimator || "");
                                }
                              } catch (err) {/* eslint-disable-next-line no-empty */}
                            }

                            // setClientId(value);
                            // setClient(value);
                          }}
                          error={errors.variation_job_id}
                          onBlur={setFieldTouched}
                          options={renderJobList()}
                        />
                      </div>
                    </div>
                  ) : (<div className="flex">
                    <div className="w-1/2">
                      <Input
                        title="PO Number"
                        id="PO_Number"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.PO_Number}
                        error={errors.PO_Number}
                      />
                    </div>
                  </div>)}

                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Client"
                        id="client"
                        value={values.client}
                        onChange={(opt, value) => {
                          setFieldValue("client", value);
                          setClientId(value);
                          setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        isLoading={clientData.isFetching}
                        options={renderClientList()}
                        error={errors.client}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon className="w-6 h-6 text-indigo-500" />
                          <button
                            type="button"
                            className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
                            onClick={() => setClientForm(true)}
                          >
                            Add New Client
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Input
                        title="Quote #"
                        id="quote_num"
                        type="text"
                        disabled
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.quote_num}
                        // error={errors.quote_num}
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label="Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                        error={errors.contact}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon
                            className={clsx(
                              !clientId ? "text-indigo-100" : "text-indigo-500",
                              "w-6 h-6",
                            )}
                          />
                          <button
                            type="button"
                            className={clsx(
                              !clientId
                                ? "text-gray-200 cursor-none"
                                : "text-gray-600 hover:text-gray-800",
                              "pl-1 font-semibold leading-5 text-sm",
                            )}
                            onClick={() => setContactForm(true)}
                            disabled={!clientId}
                          >
                            Add New Contact
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <TextArea
                    title="Scope of Work"
                    id="description"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                  />
                  <div className="w-1/2">
                    <Dropdown
                      label="Estimator"
                      id="estimator"
                      // filterByEmail={values.estimator === user?.email}
                      value={values.estimator}
                      onChange={(opt, value) => {
                        setFieldValue("estimator", value);
                      }}
                      onBlur={setFieldTouched}
                      isLoading={staffData.isFetching}
                      options={renderStaffList()}
                      // error={errors.estimator}
                    />
                  </div>
                  <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>
                  <Address
                    streetId="street"
                    streetId2="street2"
                    cityId="city"
                    postalId="postal"
                    streetVal={values.street}
                    street2Val={values.street2}
                    cityVal={values.city}
                    postalVal={values.postal}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <h3 className="block text-sm font-medium text-gray-700 w-1/2 ml-4 mt-1">
                    How will this job be estimated?
                  </h3>
                  <div className="w-1/2 ml-4 mt-1 grid grid-cols-2 gap-2">
                    <div>
                      <RadioButton
                        inputId="howestimateMeters"
                        name="howestimateMeters"
                        value="Meters"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "Meters"}
                      />
                      <label htmlFor="howestimateMeters" className="p-radiobutton-label ml-1">
                        Meters
                      </label>
                    </div>

                    <div>
                      <RadioButton
                        inputId="howestimateTotalED"
                        name="howestimateTotalED"
                        value="Total ED"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "Total ED"}
                      />
                      <label htmlFor="howestimateTotalED" className="p-radiobutton-label ml-1">
                        Total ED
                      </label>
                    </div>
                  </div>
                </form>
                <div className="w-full">
                  <div className="w-4/6 mx-auto">
                    <div className="flex justify-start mb-4">
                      <QuoteTab
                        tabs={tabs}
                        handleChange={(tab) => setCurrentTab(tab)}
                        currentTab={currentTab}
                      />
                    </div>
                    {currentTab === "Rates" ? (
                      <Rates
                        isEditing={formType === "edit"}
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          // 
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>

              {estimatedWay !== null && (
                <div className="px-4">
                  <QuoteLines
                    quoteLines={quoteLines}
                    setQuoteLines={setQuoteLines}
                    zoneOptions={zoneValues}
                    zoneLabels={zoneLabels}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                  <AdditionalItems
                    items={additionalItems}
                    setItems={setAdditionalItems}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                </div>
              )}
              <div className="px-2">
                <QuotePhotos
                  setFieldValue={setFieldValue}
                  data={formType !== "create" ? quotePhotosData : []}
                />
                <br />
              </div>

              <div className="w-3/6">
                <TextArea
                  title="Additional Conditions"
                  id="terms"
                  type="text"
                  handleChange={handleChange}
                  value={values.terms}
                  rows={10}
                />
              </div>

              <Totals
                weekTotal={weekTotal}
                total={total}
                erectDismantleTotal={erectDismantleTotal}
                additionalTotal={additionalTotal}
              />
            </div>
          )}
        </Formik>
      </div>
      <div className="pl-4 mt-6">
        {formType === "edit" ? (
          <div className="flex space-x-4">
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && !redirectOutput}
              size="sm"
            >
              Save & Exit
            </Button>
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && redirectOutput}
              size="sm"
              onClick={() => setRedirectOutput(true)}
            >
              Save & View
            </Button>
          </div>
        ) : (
          <Button type="submit" form="quoteForm" isLoading={quoteCreating} size="sm">
            Create Quote
          </Button>
        )}
      </div>
      <ClientForm
        heading="Create Client"
        open={clientForm}
        setOpen={setClientForm}
        setClientId={setClientId}
        refetch={clientData.refetch}
      />
      {clientId && contactForm && (
        <ContactForm
          heading="Create Contact"
          open={contactForm}
          setOpen={setContactForm}
          formType="create"
          client={clientId}
          setContactId={setContactId}
        />
      )}
    </div>
  );
}

function formatRateText(rates) {
  return `
  Roof structures:
    This quotation allows for plank protection to roofs, canopies, decks, verandas, or any other existing structures on which scaffolding needs to be erected. However, Frontier assumes these structures can support the scaffold and workload. It is on the sole discretion of the client to ensure the strength of the structures by seeking engineering advice at the client’s own costs prior to erection.
    Working on roofs:
    Working on roofs can cause dents, cracks, scratches etc; we will take the utmost care to avoid this, but take no responsibility if any damage should occur, as roofs are generally not designed for being worked on.

    Power lines:
    Government regulations prohibit the erection and use of any scaffolding closer than 4m to power lines without the power lines being sleeved or disconnected and without the consent from the property owner. Unless the client otherwise advises, with acceptance of this quote the property owner automatically has given consent for work to take place near the power lines. The minimum charge for an electrician to inspect the site and sleeve/de-sleeve the lines is $250 plus GST. In case the public power lines run along the property Wellington Electricity is in charge and further costs may apply including any traffic management etc. All costs will be an additional charge to this quote. All users of the scaffold must follow the instructions on the Electrical Safety Tag in the vicinity of the power lines.

    Plants:
    Any plants closer than 1m to the walls need to be trimmed by the client prior to commencement of scaffolding.

    Satellite dishes / aerials:
    Scaffolding may interfere with the TV reception and/or the dish/aerial is in the way of the scaffolding. This quote does not include relocation of any sat dish/aerial. However, Frontier is happy to assist in this matter.

    Ties and fixing:
    To secure the scaffolding wall ties need to be periodically screwed into the building structure and will be removed on dismantling. Any remedial work will be the client’s responsibility.

    Garages, driveways and entrances:
    Parts of the scaffolding will block the entrance, garage and/or driveway while the scaffolding is in place.

    Neighbours:
    Frontier needs to erect some scaffolding on the    side of the house onto the neighbour’s ground. It is the client’s responsibility to obtain permission from the neighbours to allow Frontier access to erect, dismantle and transport parts of scaffolding on their property prior to commencement of scaffolding erection.

    Car parks and driveways:
    Please note parts of the scaffolding will block the car parks and/or driveway while the scaffolding is in place.

    Council permits:
    A Corridor Access Request (CAR) is required when any work commences on public road assets such as footpaths, carriageways and berms. The CAR should be generated by the principal of the site and is to be set up via www.submitica.co.nz. Once the CAR is in place please liaise with Frontier who will then add the Road Usage License (RUL). For a service fee of $490 plus GST Frontier can set up the CAR on behalf of the principal. Please note: This quote does not include costs for any further requirements of the council to the scaffolding construction as per description above and/or any traffic management costs or any other costs.

    Council parking permit:
    A parking permit for parking the Frontier truck in front of the jobsite needs to be in place prior to commencement of the erection and dismantle of the job. This can be obtained by the client or by Frontier. For a service fee of $250 plus GST Frontier can organise the permit. Please note: This quote does not include any costs (e.g. traffic management, council fees) for receiving the parking permit.

    Engineering:
    Any costs of engineering for e.g. designs and/or certificates requested by the client or by any authorities are not included in this quote.

    Foundation ties:
    To secure the scaffolding plus wrapping against uplifts wall ties need to be drilled into the concrete foundation of the house where possible and will be removed on dismantling. Any remedial work when ties are removed is the client’s responsibility.

    Asbestos Management:
    As there is asbestos present at the property the client must ensure that the job site including the scaffolding is safe for the Frontier staff to enter for scaffolding checks, alterations and dismantling. Frontier will request a copy of the asbestos management plan and a copy of the test results once asbestos removal is completed.

    Neighbouring Property
    Scaffolding will be built on the neighbour’s driveway on the left-hand side.

    Neighbouring Businesses:
    Scaffolding will be erected in front of neighbouring business.  It is the client’s responsibility to obtain permission from the businesses affected to allow Frontier access to erect and dismantle the scaffolding.
    Please see the diagram below for an indication of the areas allowed for within this quote:

    Shrink-wrap:
    Weather protection will be provided to the walls by wrapping a structure approx. 1.5m above the gutter and approx. 1m into the roof to divert rainwater into the gutters.
    Please note: This price does not include the engineers report or the wrapping itself.

    The job must be done in two stages as scaffolding needs to be built on the lower roof for edge protection on the top roof.

    Windows will be kept clear of scaffolding, but where not possible, the scaffolding may need to be adjusted which will be charged at $90.00/per man/per hour plus GST.


    The following conditions apply to this job:

    Our condition on acceptance of this quote is that we will require a 100% upfront payment of the EDC.

    EDC payment to be paid prior to commencement of erecting of scaffold. Balance to be paid 7 days after invoice has been sent upon removal of scaffolding.

    Terms:
    Hire charges start on the date on which the erection of the scaffolding is completed and continue until the date of dismantling of the scaffolding as advised by the client (off-hire date). Both dates will be charged as whole days.

    Alterations or extra work will be charged at $90.00 plus GST/hour/man plus hire charges for additional scaffolding material.  Extra work includes but is not limited to all modifications, moves, repairs, partly dismantling or adjustments of the scaffolding.

  `;
}
