import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../../supabase";
import {checkJobStatus} from "./checkJobStatus"
import { useNotificationStore } from "../../../store/notifications";

async function updateTask({ payload, taskId }) {
  const { data, error } = await supabase.from("job_tasks").update(payload).match({ id: taskId });

  supabase.from("edinvoices").update({
    PO_Number: payload?.PO_Number,
    zone: payload?.zone, zone_label: payload?.zone_label, type: payload?.type, description: payload?.description,
    erect_percent: payload?.percentage_erect, dismantle_percent: payload?.percentage_dismantle,
    complete_percent: payload?.percentage_complete
  }).match({ task_id: taskId, status: "Pending" }).then((dataNewInvoice) => {/* eslint-disable-next-line no-empty */});

  supabase.from("weekly_hire_invoices").update({
    type: payload?.type, zone_label: payload?.zone_label, zone: payload?.zone,
    description: payload?.description, completed: payload?.percentage_complete
  }).match({ task_id: taskId, status: "Pending" }).then((dataInvoice) => {/* eslint-disable-next-line no-empty */});

  checkJobStatus(data[0].job_id);
  
  if (error) {
    throw new Error(error.messge);
  }

  try {
    await updateAppenateTask(data)
  // eslint-disable-next-line no-empty
  } catch (err) {/* eslint-disable-next-line no-empty */}

  return data;
}

export function useUpdateTask() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();
  let refreshType = "";

  return useMutation(
    ({ payload, taskId, type = "tasks" }) => {
      refreshType = type;
      return updateTask({ payload, taskId });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(refreshType);
        queryClient.refetchQueries("day_work_task");
        addNotification({
          isSuccess: true,
          heading: "Success!",
          content: `Successfully updated task.`,
        });
      },
      onError: (err) => {
        addNotification({
          isSuccess: false,
          heading: "Failed update task",
          content: err?.message,
        });
      },
      mutationFn: updateTask,
    },
  );
}

async function updateAppenateTask(tasks) {
  const taskPayload = [];

  tasks.map((task) =>
    taskPayload.push([
      task.id,
      task.job_id || "",
      task.zone || "",
      task.zone_label || "",
      task.type || "",
      task.description || "",
      task.complete || "",
    ]),
  );
  return axios.post("https://frontier-server-scaffm8.herokuapp.com/api/data-sync", {
    id: "091cc2e0-831e-4341-8108-af3500047302",
    data: taskPayload,
  });
}
